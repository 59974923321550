<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <!-- form -->
      <validation-observer ref="licenceCreate">
        <b-form>
          <b-row>
            <b-col md="12">
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="FileTextIcon"
                    width="24"
                    height="24"
                  />
                  <h2>License details</h2>
                </div><b-form-group
                  label="Category"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Category"
                    vid="category"
                    rules="required"
                  >
                    <v-select
                      v-model="form.category"
                      :options="categories"
                      disabled
                      :reduce="category => category.id"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Level"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Level"
                    vid="level"
                    rules="required"
                  >
                    <v-select
                      v-model="form.level"
                      :options="levels"
                      disabled
                      :reduce="level => level.id"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Type"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Type"
                    vid="type"
                    rules="required"
                  >
                    <v-select
                      v-model="form.type"
                      :options="types"
                      disabled
                      :reduce="type => type.id"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Site"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Site"
                    vid="site_id"
                  >
                    <v-select
                      v-model="site"
                      :options="siteList"
                      placeholder="Enter name"
                      label="name"
                      class="selector-height"
                      @search="onSearch($event, 'site')"
                      @input="onSelectedUser($event, 'site')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="UserIcon"
                    width="24"
                    height="24"
                  />
                  <h2>Owner details</h2>
                </div>
                <div
                  v-if="!site"
                  class="mb-1"
                >
                  <multiselect
                    v-model="selected"
                    :options="options"
                    multiple
                    placeholder="Select owner’s type"
                    class="multiselect"
                    :searchable="false"
                    @select="onSelect"
                  />
                </div>
                <b-form-group
                  v-if="$can('view', SELECT_CLIENT) && (selected.includes('Client') || (client || reseller))"
                  label="Client"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Client"
                    vid="client_id"
                  >
                    <v-select
                      v-model="client"
                      :options="clients"
                      placeholder="Enter name"
                      label="name"
                      :class="selected[0] !== 'Client' ? 'disabled-clear owner-select' : 'owner-select'"
                      @search="onSearch($event, 'client')"
                      @input="onSelectedUser($event, 'client')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="$can('view', SELECT_RESELLER) && (selected.includes('Reseller') || (reseller || distributor))"
                  label="Reseller"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Reseller"
                    vid="reseller_id"
                  >
                    <v-select
                      v-model="reseller"
                      :options="resellers"
                      placeholder="Enter name"
                      label="name"
                      :class="selected[0] !== 'Reseller' ? 'disabled-clear owner-select' : 'owner-select'"
                      @search="onSearch($event, 'reseller')"
                      @input="onSelectedUser($event, 'reseller')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="$can('view', SELECT_DISTRIBUTOR) && (selected.includes('Distributor') || (distributor || masterDistributor))"
                  label="Distributor"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Distributor"
                    vid="distributor_id"
                  >
                    <v-select
                      v-model="distributor"
                      :options="distributors"
                      placeholder="Enter name"
                      :class="selected[0] !== 'Distributor' ? 'disabled-clear owner-select' : 'owner-select'"
                      label="name"
                      @search="onSearch($event, 'distributor')"
                      @input="onSelectedUser($event, 'distributor')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="$can('view', SELECT_MASTER_DISTRIBUTOR) && (selected.includes('Master Distributor') || masterDistributor)"
                  label="Master Distributor"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Master Distributor"
                    vid="master_distributor_id"
                  >
                    <v-select
                      v-model="masterDistributor"
                      :options="masterDistributors"
                      :class="selected[0] !== 'Master Distributor' ? 'disabled-clear owner-select' : 'owner-select'"
                      placeholder="Enter name"
                      label="name"
                      @input="onSelectedUser($event, 'master_distributor')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div
                v-if="item"
                class="section-block  mb-4"
              >
                <div class="section-block__title">
                  <feather-icon
                    icon="InfoIcon"
                    width="24"
                    height="24"
                  />
                  <h2>Additional details</h2>
                </div>
                <div class="status-row">
                  <div>
                    <p class="status-label">
                      Status:
                    </p>
                    <b-badge
                      pill
                      :variant="statusColor"
                    >
                      {{ form.status.name }}
                    </b-badge>
                  </div>
                  <div class="my-1">
                    <feather-icon
                      v-if="!form.is_enable"
                      v-b-tooltip.hover
                      icon="AlertCircleIcon"
                      size="24"
                      title="Disabled"
                      style="color: #dc3545"
                    />
                    <feather-icon
                      v-else
                      v-b-tooltip.hover
                      icon="CheckCircleIcon"
                      size="24"
                      title="Enabled"
                      style="color: #28a745"
                    />
                  </div>
                </div>
                <b-form-group
                  label="Enabled/Disabled"
                  label-class="label-class"
                >
                  <b-form-checkbox
                    v-model="form.is_enable"
                    name="is_enable"
                    switch
                    inline
                  />
                </b-form-group>
                <b-form-group
                  label="Creation Date"
                  label-class="label-class"
                >
                  <b-form-datepicker
                    v-model="form.created_at"
                    locale="en"
                    disabled
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </b-form-group>
                <b-form-row
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Activation Date"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <b-form-datepicker
                      v-model="form.activation_at"
                      locale="en"
                      disabled
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Renewal Date"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <b-form-datepicker
                      v-model="form.renewal_at"
                      locale="en"
                      disabled
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />
                  </b-form-group>
                </b-form-row>
                <b-form-group
                  label-class="label-class"
                >
                  <div class="label-action-container">
                    <p>Expiry Date</p>
                    <b-button
                      v-if="item.client"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      class="d-flex align-items-center"
                      variant="transparent"
                      :disabled="emailButtonLoading"
                      @click.prevent="sendEmail"
                    >
                      Reminder Email
                      <b-spinner
                        v-if="emailButtonLoading"
                        class="ml-1"
                        style="width:14px; height:14px"
                        label="Spinning"
                      />
                    </b-button>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Expiry Date"
                    vid="expiry_at"
                  >
                    <b-form-datepicker
                      id="expiry_at"
                      v-model="form.expiry_at"
                      :min="minDate"
                      locale="en"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </div>
            </b-col>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="submit-form-button"
                @click.prevent="validationForm"
              >
                <div
                  v-if="isFormSubmitting"
                >
                  <b-spinner
                    label="Spinning"
                    small
                  />
                </div>
                {{ isFormSubmitting ? '' : 'Submit' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormDatepicker, BBadge, BSpinner, VBTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required, max, alphaNum, numeric, min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import rolesPermission from '@/libs/acl_roles/rolesPermission'
import { mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { debounce } from '@/utils/utils'
import Multiselect from 'vue-multiselect'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormDatepicker,
    BFormCheckbox,
    BBadge,
    BSpinner,
    vSelect,
    Multiselect,
  },
  data() {
    return {
      form: {},
      required,
      loading: false,
      emailButtonLoading: false,
      min,
      max,
      alphaNum,
      numeric,
      id: null,
      selected: [],
      options: ['Client', 'Reseller', 'Distributor', 'Master Distributor'],
      minDate: '',
      isFormSubmitting: false,

      SELECT_MASTER_DISTRIBUTOR: rolesPermission.SELECT_MASTER_DISTRIBUTOR,
      SELECT_DISTRIBUTOR: rolesPermission.SELECT_DISTRIBUTOR,
      SELECT_RESELLER: rolesPermission.SELECT_RESELLER,
      SELECT_CLIENT: rolesPermission.SELECT_CLIENT,
      SELECT_SITE: rolesPermission.SELECT_SITE,
    }
  },
  computed: {
    ...mapFields('user', [
      'masterDistributor',
      'distributor',
      'reseller',
      'client',
      'site',
      'masterDistributorList',
      'distributorList',
      'resellerList',
      'clientList',
      'siteList',
      'clients',
      'resellers',
      'distributors',
      'masterDistributors',
    ]),
    ...mapFields('licence', [
      'levels',
      'categories',
      'types',
      'item',
    ]),
    statusColor() {
      let colorVariant
      switch (this.form.status.id) {
        case 'un-allocated':
          colorVariant = 'info'
          break
        case 'allocated':
          colorVariant = 'warning'
          break
        case 'activated':
          colorVariant = 'success'
          break
        case 'renewed':
          colorVariant = 'primary'
          break
        case 'ceasing':
          colorVariant = 'secondary'
          break
        case 'expired':
          colorVariant = 'danger'
          break
        default:
          colorVariant = 'danger'
      }
      return colorVariant
    },
  },
  watch: {
    distributor(val) {
      if (!val) {
        this.selected = ['Master Distributor']
        this.client = null
        this.reseller = null
      } else if (!this.reseller && !this.client) {
        this.selected = ['Distributor']
      }
    },
    reseller(val) {
      if (!val) {
        this.selected = ['Distributor']
        this.client = null
      } else if (!this.client) {
        this.selected = ['Reseller']
      }
    },
    masterDistributor(val) {
      if (!val) {
        this.selected = ['Master Distributor']
        this.distributor = null
        this.reseller = null
        this.client = null
      } else if (!this.reseller && !this.distributor && !this.client) {
        this.selected = ['Master Distributor']
      }
    },
    client(val) {
      if (!val) {
        this.selected = ['Reseller']
      } else {
        this.selected = ['Client']
      }
    },
  },
  async created() {
    this.id = this.$route.params.id
    this.loading = true
    if (this.id) {
      await this.getOptions()
      await this.fetchModel(this.id)

      const payload = {}
      if (this.client) {
        payload.company_id = this.client.id
      } else if (this.reseller) {
        payload.company_id = this.reseller.id
      } else if (this.distributor) {
        payload.company_id = this.distributor.id
      } else if (this.masterDistributor) {
        payload.company_id = this.masterDistributor.id
      }
      this.search({ query: '', company_type: 'site' }, this.fetchSiteList)
      this.fetchCompaniesList({ query: '', company_type: 'master-distributor' })
      this.fetchCompaniesList({ query: '', company_type: 'distributor' })
      this.fetchCompaniesList({ query: '', company_type: 'reseller' })
      this.fetchCompaniesList({ query: '', company_type: 'client' })

      if (this.$can('view', this.SELECT_MASTER_DISTRIBUTOR)) {
        this.options = ['Client', 'Reseller', 'Distributor', 'Master Distributor']
      } else if (this.$can('view', this.SELECT_DISTRIBUTOR)) {
        this.options = ['Client', 'Reseller', 'Distributor']
      } else if (this.$can('view', this.SELECT_RESELLER)) {
        this.options = ['Client', 'Reseller']
      } else if (this.$can('view', this.SELECT_CLIENT)) {
        this.options = ['Client']
      } else {
        this.options = []
      }

      if (Object.keys(payload).length) {
        this.fetchLicenceSelectedCompany(payload)
      }
    } else {
      this.$router.push({ name: 'error-404' })
    }
    this.loading = false
    this.minDate = new Date()
  },
  methods: {
    ...mapActions('user', [
      'fetchCompaniesList',
      'fetchLicenceSelectedCompany',
    ]),
    ...mapActions('licence', [
      'getOptions',
      'getItem',
      'updateItem',
      'sendReminderEmail',
    ]),
    ...mapMutations({
      selectCompanies: 'user/SELECT_COMPANIES',
      resetUserState: 'user/RESET_STATE',
    }),
    onSelect(option) {
      if (option === 'Client') {
        this.selected = ['Client']
      }

      if (option === 'Reseller') {
        this.selected = ['Reseller']
        this.client = null
      }

      if (option === 'Distributor') {
        this.selected = ['Distributor']
        this.reseller = null
        this.client = null
      }

      if (option === 'Master Distributor') {
        this.selected = ['Master Distributor']
        this.distributor = null
        this.reseller = null
        this.client = null
      }
    },
    async fetchModel(id) {
      try {
        await this.getItem(id)
        this.selectCompanies(this.item)
        this.form = {
          ...this.item,
        }
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    onSearch(query, type) {
      if (!query) {
        return
      }
      let payload = {
        query,
        company_type: type,
      }

      if (type === 'distributor') {
        this.search(payload, this.fetchCompaniesList)
      }

      if (type === 'reseller') {
        if (this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        this.search(payload, this.fetchCompaniesList)
      }

      if (type === 'client') {
        if (this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        if (this.reseller) {
          payload = {
            ...payload,
            ...{ reseller_id: this.reseller.id },
          }
        }

        this.search(payload, this.fetchCompaniesList)
      }

      if (type === 'site') {
        if (this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        if (this.reseller) {
          payload = {
            ...payload,
            ...{ reseller_id: this.reseller.id },
          }
        }

        if (this.client) {
          payload = {
            ...payload,
            ...{ client_id: this.client.id },
          }
        }

        this.search(payload, this.fetchSiteList)
      }
    },
    search: debounce((payload, fetchAction) => {
      fetchAction({ ...payload })
    }, 350),
    async onSelectedUser(option, type) {
      let payload = {}
      if (type !== 'site') {
        payload.company_id = option.id
      }
      if (type === 'master_distributor') {
        this.distributor = null
        this.reseller = null
        this.client = null
        this.distributorList = []
        this.resellerList = []
        this.clientList = []

        this.fetchLicenceSelectedCompany(payload)
      }

      if (type === 'distributor') {
        this.reseller = null
        this.client = null
        this.site = null
        this.siteList = []
        this.resellerList = []
        this.clientList = []
        this.selected = ['Distributor']
        if (!option) {
          return
        }
        this.fetchLicenceSelectedCompany(payload)
      }

      if (type === 'reseller') {
        this.client = null

        this.clientList = []
        if (!option) {
          return
        }
        this.selected = ['Reseller']
        this.fetchLicenceSelectedCompany(payload)
      }

      if (type === 'client') {
        if (!option) {
          return
        }
        this.selected = ['Client']
        if (!this.reseller && this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        payload = {
          ...payload,
        }

        this.fetchLicenceSelectedCompany(payload)
      }

      if (type === 'site') {
        if (!option) {
          return
        }
        payload = {
          ...payload,
          ...{ site_id: option.id },
        }
        this.fetchLicenceSelectedCompany(payload)
      } else {
        this.site = null
      }
    },
    async fetchSiteList({ ...payload }) {
      try {
        const { data } = await this.$http.get('/site/list', { params: { ...payload } })

        this.siteList = data.data
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async validationForm() {
      const valid = await this.$refs.licenceCreate.validate()

      if (valid) {
        try {
          let payload = {
            expiry_at: this.form.expiry_at,
            is_enable: this.form.is_enable ? 1 : 0,
          }

          payload = {
            ...payload,
            ...{ distributor_id: this.distributor ? this.distributor.id : null },
          }

          payload = {
            ...payload,
            ...{ reseller_id: this.reseller ? this.reseller.id : null },
          }

          payload = {
            ...payload,
            ...{ client_id: this.client ? this.client.id : null },
          }

          payload = {
            ...payload,
            ...{ site_id: this.site ? this.site.id : null },
          }

          this.isFormSubmitting = true
          await this.updateItem({
            ...{ id: this.id },
            ...payload,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'licence-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.licenceCreate.setErrors(fields)
          }
        } finally {
          this.isFormSubmitting = false
        }
      }
    },
    async sendEmail() {
      this.emailButtonLoading = true
      try {
        await this.sendReminderEmail(this.id)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Server error',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.emailButtonLoading = false
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetUserState()

    next()
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
